<script setup lang="ts">
import { Navigation, Pagination } from 'swiper/modules'

const modules = [Pagination, Navigation]
</script>

<template>
  <swiper
    slides-per-view="auto"
    :space-between="20"
    :threshold="3"
    :css-mode="false"
    navigation
    :pagination="{ clickable: true }"
    :modules="modules"
    class="v-swiper relative"
    itemscope
    itemtype="http://schema.org/ItemList"
    v-bind="$attrs"
  >
    <slot />
  </swiper>
</template>

<style scoped lang="scss">
.v-swiper {
  display: flex;
  flex-direction: column;
}
</style>
